import React, { useState } from 'react'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'

import Layout from 'src/layouts/BaseLayout'
import HeroChequeEspecial from '../cheque-especial-sucesso/sections/hero/_index'

import QRcodeModalExpirado from './assets/images/qrcode-modal-expirado.svg'

import pageContext from './pageContext.json'

const ChequeEspecialExpirado = () => {
  const urlMobileEspirado = 'https://bancointer.go.link/chequeEspecial?adj_t=1bcpz8ol&adj_adgroup=contadigital&adj_creative=onboarding_chequeespecial_expirado&adj_fallback=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_expirado&adj_redirect_macos=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_expirado'
  const domReady = useDomReady()
  const [ isOpenModal, setIsOpenModal ] = useState(false)

  const openModalExpirado = domReady && (
    <Modal isModalOpen={isOpenModal} setIsModalOpen={setIsOpenModal} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpenModal}
        setIsModal={setIsOpenModal}
        title='Acesse o nosso Super App'
        subtitle="<span class='text-left'>Você será redirecionado para o Super App para utilizar o Cheque Especial.</span>"
        instructions="<span class='text-left'>Abra a câmera do seu celular e aponte para o QR Code acima. É rápido e fácil!</span>"
        qrCode={QRcodeModalExpirado}
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModalExpirado}
      <HeroChequeEspecial
        title='Faça a ativação do Cheque Especial pelo Super App'
        subTitle='O link de ativação do Cheque Especial expirou, mas você pode acessar o Super App para fazer ativação.'
        setIcon='alert'
        url={urlMobileEspirado}
        setIsOpenModal={setIsOpenModal}
      />
    </Layout>
  )
}

export default ChequeEspecialExpirado
